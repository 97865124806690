var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "checkFiles",
    _vm._b(
      { on: { error: _vm.onerror, submit: _vm.onsubmit } },
      "checkFiles",
      _vm.$attrs,
      false
    ),
    [_vm._v(" 123132 ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import "core-js/modules/es.function.name"; //
//
//
//
//
//
//
//
//
//

import checkFiles from './checkFiles.vue';
// import { uploadFile } from '@/api/global'
export default {
  components: { checkFiles: checkFiles },
  props: {
    autoUpload: {
      type: Boolean,
      "default": true } },


  data: function data() {
    return {
      files: [] };

  },
  watch: {},
  computed: {},
  methods: {
    onsubmit: function onsubmit(files) {
      console.log(files);
      var len = files.length;

      if (len) {
        this.uploadFile(files);
        // let fe = new FormData()
        // for (let i = 0; i < len; i++) {
        // 	const element = files[i]
        // 	// fe.append('file', file) //传文件
        // 	fe.append(files[i].name, files[i])
        // }
        // fe.append('file', file) //传文件
      } else {
        console.log('清空');
      }
    },
    // 每次传一个文件 上传多次
    uploadFile: function uploadFile(files) {
      for (var i = 0; i < files.length; i++) {
        var fe = new FormData();

        fe.append('file', files[i]); // 传文件
        // uploadFile(fe).then(res => {
        // 	if (res.data) {
        // 		console.log(res.data)
        // 		// this.$emit('getUrl', res.data)
        // 		// Message({
        // 		//   type: 'success',
        // 		//   message: '上传成功！'
        // 		// })
        // 	}
        // })
      }
    },
    // 多文件一起上传
    uploadFiles: function uploadFiles(files) {
      var fe = new FormData();

      for (var i = 0; i < files.length; i++) {
        fe.append(files[i].name, files[i]);
      }
      // uploadFile(fe).then(res => {
      // 	if (res.data) {
      // 		console.log(res.data)
      // 		// this.$emit('getUrl', res.data)
      // 		// Message({
      // 		//   type: 'success',
      // 		//   message: '上传成功！'
      // 		// })
      // 	}
      // })
    },
    onerror: function onerror(err) {
      console.log(err);
    } },

  created: function created() {
    // this.getData()
  },
  mounted: function mounted() {} };